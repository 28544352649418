<template>
  <b-card>
    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >

        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-img
            :src="
              companyData.company_image_url
                ? `${companyData.company_image_url}`
                : require('@/assets/images/placeholder/no-image.jpg')
            "
            class="float-left"
            rounded
            style="width: 120px; height: 120px"
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ companyData.company_name }} ({{ companyData.uen_no }})
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="$checkRights(this.$route.meta.section, 'update')"
                :to="{ name: 'companies-edit', params: { id: companyData.id } }"
                variant="primary"
              >
                Edit Company Details
              </b-button>
              <b-button
                v-if="companyData.active"
                variant="outline-danger"
                class="ml-md-1 mt-1 mt-xl-0 mt-md-0"
                @click="confirmDeactivateRecord"
              >
                Deactivate
              </b-button>

              <b-button
                v-else
                variant="outline-success"
                class="ml-md-1 mt-1 mt-xl-0 mt-md-0"
                @click="confirmReactivateRecord"
              >
                Reactivate
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BImg,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BCol,
    BImg,
    BRow,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // deactivate
    confirmDeactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'delete')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to deactivate "${this.companyData.company_name}"`,
            {
              title: 'Confirm Company Deactivation',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Deactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deactivateRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Inadequate permission',
              icon: 'AlertCircleIcon',
              text: 'You dont have enough rights to deactivate company',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    deactivateRecord() {
      this.showDeactivateSuccess()
    },
    showDeactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params

      this.isLoading = true
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      this.$http
        .delete(`/api/companies/${id}`, {})
        .then(response => {
          this.$emit('deactivated')
          const { success, output } = response.data

          if (success) {
            this.$toastSuccess('Account Operations', 'Commpany deactivated successfully.')
          } else {
            this.$toastDanger('Account Operations', 'Could not deactivate this company, please try again later')
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Account Operations', 'Could not deactivate the company, please try again later')

          this.isLoading = false
        })
    },

    // deactivate
    confirmReactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'delete')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to reactivate "${this.companyData.company_name}"`,
            {
              title: 'Confirm Company Reactivation',
              size: 'sm',
              okVariant: 'success',
              okTitle: 'Reactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.reactivateRecord()
            }
          })
      } else {
        this.$toastDanger('Inadequate permission', 'You dont have enough rights to reactivate company')
      }
    },
    reactivateRecord() {
      this.showReactivateSuccess()
    },
    showReactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params

      this.isLoading = true
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      this.$http
        .put(`/api/companies/${id}/reactivate`, {})
        .then(response => {
          this.$emit('reactivated')
          const { success, output } = response.data

          if (success) {
            this.$toastSuccess('Account Operations', 'Commpany reactivated successfully.')
          } else {
            this.$toastDanger('Account Operations', 'Could not reactivate this company, please try again later')
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Account Operations', 'Could not reactivate this company, please try again later')

          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
